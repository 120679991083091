<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 bg-canvas" data-cmp="OpportunityDetails">
        <HeaderMain></HeaderMain>

        <!-- subnavigation -->
        <div class="container-fluid border-bottom bg-white shadow-sm position-relative py-2 px-4 d-flex align-items-center">
            <span>
                <ButtonCircle class="btn-primary" icon="navigate_before" :onClick="$router.back"></ButtonCircle>

                <nav class="d-inline-block ms-3" style="--bs-breadcrumb-divider: '›';">
                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                        <li class="breadcrumb-item">
                          <a class="text-decoration-none" href="#"
                            @click.prevent="$root.goto( $root.links.FORMS )"
                          >Forms</a>
                        </li>
                        <li v-if="current?.form?.title" class="breadcrumb-item">{{ current?.form?.title }}</li>
                        <li v-if="current?.field" class="breadcrumb-item">{{ current?.field?.title }}</li>
                    </ol>
                </nav>
            </span>

            <span class="flex-fill"></span>

            <span>
                <!-- <ButtonCircle class="" icon="file_upload"></ButtonCircle>
                <ButtonCircle class="ms-2" icon="library_add"></ButtonCircle> -->

                <OptionsDropdown
                    :context="current?.form"
                    :onClick="formOnOption" 
                    :collection="optionsForm"
                ></OptionsDropdown>

                <ButtonMain class="ms-2 rounded-pill btn-sm fs-6 disabled" icon="east" iconPosition="right" :onClick="viewData"
					:class="[!$route.params?.form? 'disabled' : '']"
				>View Data</ButtonMain>
            </span>
        </div>


		<!-- editable content -->
        <div class="bg-white flex-fill">
			<div class="d-flex flex-row h-100">
				<!-- OPTIONS -->
				<div class="--content-editor-extras panel-a min-height">
					<div class="d-flex flex-row border-bottom align-items-center py-2 px-4">
						<h4 class="mb-0 pb-0">General</h4>

						<!-- <div class="d-inline-block flex-fill text-end">
							<button type="submit" class="btn btn-sm py-0 btn-outline-dark text-uppercase me-3" @click="addCustomField">Custom</button>
							<button type="submit" class="btn btn-sm py-0 btn-dark text-uppercase" @click="addFormField">ADD FIELD</button>
						</div> -->
					</div>

					<div class="px-4">
                        <form class="py-4 flex-fill overflow-scroll" @submit.prevent="">

                            <div class="mb-3">
                                <label for="field-title" class="form-label">Title</label>
                                <input type="text" class="form-control text-input fs-5" id="form-title" v-model="info.title">
                                <div class="form-text">Form named.</div>
                            </div>


                            <div class="mb-3">
                                <label for="v" class="form-label">Description</label>
                                <textarea type="text" class="form-control text-input fs-5" id="form-excerpt" v-model="info.excerpt"></textarea>
                                <div class="form-text">Form description.</div>
                            </div>


                            <div class="mb-3">
                                <label for="meta-type" class="form-label">Type</label>
                                <input type="text" class="form-control text-input fs-5" id="meta-type" v-model="meta.type">
                                <div class="form-text">Type of data <i>( e.g. "email" )</i> —use a singular word.</div>
                            </div>

                            <div class="mb-3">
                                <label for="eta-group" class="form-label">Group</label>
                                <input type="text" class="form-control text-input fs-5" id="meta-group" v-model="meta.group">
                                <div class="form-text">Group name (e.g. main).</div>
                            </div>
                        </form>
					</div>
				</div>


				<!-- FIELDS -->
				<div class="--content-editor-sidebar panel-b min-height border-start flex-fill">
					<div class="d-flex flex-row border-bottom align-items-center py-2 px-4">
						<h4 class="mb-0 pb-0">Fields</h4>

						<div class="d-inline-block flex-fill text-end">
							<!-- <button type="submit" class="btn btn-sm py-0 btn-outline-dark text-uppercase me-3" @click="addCustomField">Custom</button> -->
							<button type="submit" class="btn btn-sm py-0 btn-outline-dark text-uppercase" @click="addFormField">ADD FIELD</button>
						</div>
					</div>



					<div class="px-2 pt-2">
						<ul class="form-fileds list-group list-group-flush px-2">
							<li v-for="(field, index) in fields" :key="index" 
								:id="`list-group-item-${field.name}`"
								:class="[field?.key == current?.field?.key? 'active' : '']"
								class="list-group-item d-flex align-items-center rounded-3">
								<span class="material-icons-outlined float-start text-muted">
									{{fieldIcon( field?.type || 'text')}}
								</span>
								<a href="#" @click.prevent="setCurrentField(field)" class="ms-4 text-decoration-none text-reset fw-500 flex-fill">{{ field.title }}</a>

								<span class="list-group-item-options">
									<OptionsDropdown class="" :context="field" :collection="optionsField" :onClick="fieldOnOption"></OptionsDropdown>
								</span>
							</li>
						</ul>
					</div>
				</div>


				<!-- DETAILS -->
				<div class="--content-editor-details panel-c min-height border-start">
					<FieldDetails class="h-100" ref="fieldDetails" :update="updateFieldData"></FieldDetails>
				</div>
			</div>
        </div>

		<FooterMain></FooterMain>
    </div>
</template>

<script>

import FieldDetails from "./widgets/FieldDetails.vue";
import {debounce} from "@/plugins/helper.js";

export default {

	components: {
		FieldDetails
	},

	data() {
		return {
			// forms: [],
			current: {
				form: null,
				field: null
			},
		}
	},



	computed: {
		// workbooks() {
		// 	let order = this.current?.app?.content?.workbooks;
		// 	let workbooks = this.current?.workbooks;

		// 	if(!order || !workbooks || !this.current?.app) {
		// 		return [];
		// 	}
			
		// 	let orderNum = order.map( (i) => { return parseInt(i) });
		// 	let collection = this.sortOn('ID', orderNum, workbooks);
		// 	return collection || [];
		// },

		
		collection() {
			(this.forms?.length > 0) && this.forms.map( (item) => {
				item.icon = 'article';
			});

			let order = this.current?.workbook?.content?.forms || [];

				order = order.map( (i) => { return parseInt(i) });

			let forms = this.sortOn('ID', order, this.forms);

			return forms;
		}, 


        meta: {
            get() {
                if(this.current?.form?.meta) {
                    return this.current?.form?.meta;
                } else {
                    return {};
                }
            }
        },

        info: {
            get() {
                if(this.current?.form) {
                    return this.current?.form;
                } else {
                    return {};
                }
            }
        },


		optionsForm() {
			return [
                {...this.$ui.helper.options.DETAILS, ...{disabled: true}},
                {...this.$ui.helper.options.DUPLICATE, ...{disabled: true}},
                this.$ui.helper.options.DIVIDER,
                {...this.$ui.helper.options.SHARE, ...{disabled: true}},
                this.$ui.helper.options.DIVIDER,
                this.$ui.helper.options.DELETE,
            ]
		}, 

		optionsField() {
			return [
                {...this.$ui.helper.options.DUPLICATE, ...{disabled: true}},
                this.$ui.helper.options.MOVE_TO,
                this.$ui.helper.options.DELETE,
            ]
		}, 


		// forms() {
		// 	return this.$store.state?.forms || [];
		// },

		fields() {
			return this.current?.form?.content?.fields || this.current.form?.content?.fields || [];
		},

	},

	watch: {
		// '$route' (to) {
		// 	if(this.$route.params?.workbook && to?.name == "FormsDetails") {
		// 		this.setCurrentForm();
		// 	}
		// }
	},


	methods: {

        deleteFormIntent() {
            let item = this.current.form
            this.$root.notify({
                title: 'Confirm',
                type: 'confirm',
                message: 'Would you want to delete this form (' + item.title + ')?',
                callback: async (okay)=> {
                    okay && await this.deleteForm(item);
                }
            });
        },


        async deleteForm(item) {
            await this.$store.dispatch('deleteForm', item);
            await this.$store.dispatch('getForms', true);
            
            this.$root.toast(`The form "${item.title}" has been deleted.`);
            setTimeout(()=>{
                this.$router.push( {path: '/forms'} )
            }, 500)
        }, 



        deleteFieldIntent(item) {
            this.$root.notify({
                title: 'Confirm',
                type: 'confirm',
                message: 'Would you want to delete this field (' + item.title + ')?',
                callback: async (okay)=> {
                    okay && await this.deleteField(item);
                }
            });
        },


        async deleteField(item) {
            console.log('DELETE', item)
            let content = this.current.form.content;


            for(let i=0; i<content.fields.length; i++) {
                if(content.fields[i].name == item.name) {
                    content.fields.splice(i, 1);

                    let request = {
                        ID: this.current.form.ID,
                        post_content: content
                    };

                    await this.$store.dispatch('updateForm', request);

                    this.$root.toast(`The field "${item.title}" has been deleted.`);
                }
            }
        },







		// sortOn(prop, order, items) {
		// 	let sorted = [];	
			
		// 	order.forEach( (position)=> {
		// 		items.forEach( (item) => {
		// 			if(position == item[prop]) {
		// 				sorted.push(item);
		// 			}
		// 		});
		// 	});

		// 	return sorted || items;
		// },




		viewData() {
			// this.selectAppFromWorkbookId(this.current.workbook.ID, (data) => {
			// 	let formName = this.current?.form?.name? '/' + this.current?.form?.name : '';
			// 	let path = this.$root.links.APPS + '/run/' + data.name +'/'+ this.current.workbook.name + formName;
				
			// 	setTimeout(()=>{
			// 		this.$router.push( {path: path} );
			// 	}, 500)
			// });

            this.$root.notify('TODO: View Data')
		},

		/**
		 * Form methods
		 */
		async loadForms() {
			this.forms = await this.$store.dispatch('getForms', this.current.workbook.ID);
			return this.forms;
		},


		// setCurrentForm(data=null) {

		// 	let routerMethod = 'push';
		// 	if(data == null && this.$route.params?.form && this.forms?.length > 0) {
		// 		this.forms.forEach( (item) => {
		// 			if(item?.name == this.$route.params?.form) {
		// 				data = item;
		// 			}
		// 		});
		// 	} else if(!this.$route.params?.form && this.forms?.length > 0) {
		// 		routerMethod = 'replace';
		// 		data = this.forms[0];
		// 	}

		// 	if(!data) {
		// 		return;
		// 	}

		// 	console.log('setCurrentForm', data);
		// 	this.$store.commit('SET_FORM', {name: data.name, data: data} );

		// 	let path = this.$root.links.WORKBOOKS + '/'+ this.current.workbook.name +'/' + data.name;
		// 	this.$router[routerMethod]( { path: path } );
		// 	this.current.form = this.$store.state.current.form;
		// 	this.resetAfter('form');
		// },



		async updateFormsOrder(appendId) {
			let ids = [];

			this.collection.map( (item) => {
				ids.push(item.ID);
			});

			appendId && ids.push(appendId);

			let content = this.current.workbook?.content || {}
				content.forms = ids;

			await this.$store.dispatch('updateWorkbook', {
				ID: this.current.workbook.ID,
				post_content: content
			})

			return ids;
		},




		async moveFieldToPosition(data) {
			// !this.current.workbook?.content && (this.current.workbook.content = { forms: [] });
			// let order = this.current.workbook.content.forms.map( (i) => {return parseInt(i)} )
			let currentPosition;


			for(let i=0; i<this.current.form.content.fields.length; i++) {
				if(this.current.form.content.fields[i].name == data.name) {
					currentPosition = (i + 1);
					break;
				}
			}


			this.$root.notify({
				type: 'prompt',
				title: 'Move Field',
				message: 'To which position would you like to move this field?',
				value: currentPosition,
				callback: async (okay, value) => {
					if(okay) {
						let targetPosition = parseInt(value) - 1;
							targetPosition = isNaN(targetPosition)? 0 : targetPosition;
                        let form = this.current.form;
                        let fields = this.current.form.content.fields;

                        for(let i=0; i<fields.length; i++) {
                            if(fields[i].name == data.name) {
                                fields.splice(i, 1);
                                break;
                            }
                        }

						if(targetPosition <= fields.length) {
							fields.splice(targetPosition, 0, data);
						} else {
							targetPosition = fields.length;
							fields.splice(targetPosition, 0, data);
							value = targetPosition + 1;
						}


                        let request = {
                            ID: form.ID,
                            post_content: form.content
                        };
                        request

                        await this.$store.dispatch('updateForm', request);
                        await this.refresh(true);
                        this.$root.toast(`The field ${data.title} was moved to the position "${value}".`);
					}
				}
			});
		},



        /**
         * Download, delete, or show details of a workbook based on
         * a selection from a dropdown menu.
         * 
         * @param option
         * @param option.name
         * @param option.title
         * @param option.type
         * @param option.icon
         * @param option.disabled
         * @param option.classes
         * 
         * @param form
         * @param form.ID
         * @param form.name
         * @param form.title
         * @param form.excerpt
         * @param form.content
         * @param form.meta
         * 
         */
		formOnOption(option, form) {
			console.log('formOnOption', option, form)

			switch (option?.name) {
				case 'details':
					// this.editFormIntent(form);
                    this.$root.notify('TODO: details')
					break;
				case 'duplicate':
                    this.$root.notify('TODO: duplicate')
					break;
			
				case 'share':
                    this.$root.notify('TODO: share')
					break;

				case 'delete':
					this.deleteFormIntent();
					break;

				default:
					break;
			}
		},


        /**
         * 
         * @param option
         * @param option.name
         * @param option.title
         * @param option.type
         * @param option.icon
         * @param option.disabled
         * @param option.classes
         * 
         * @param field
         * @param field.title
         * @param field.description
         * @param field.key
         * @param field.name
         * @param field.private
         * @param field.type e.g. text, color, email
         * @param field.hint
         * @param field.group
         * 
         */
		fieldOnOption(option, field) {
			console.log('fieldOnOption', option)
			console.log('field', field)

            switch (option.name) {
                case 'duplicate':
                    this.$root.notify('TODO: duplicate')
                    break;
                    
                case 'moveTo':
                    this.moveFieldToPosition(field);
                    break;
            
                case 'delete':
                    this.deleteFieldIntent(field);
                    break;
                default:
                    break;
            }
		},



		setCurrentField(data) {
			this.current.field = data;
			this.$refs.fieldDetails.attachSource(this.current.field);

            let path = `/forms/${this.$route.params.form}/${data.name}`;
            this.$router.push( {path: path} )
			console.log(this.current.field);
		},



        /**
         * Edit Strategy details
         * 
         * @param {Object} callback
         * 
         */
        // createForm(callback) {
        //     // let root = this.$root;
        //     let modal = this.$root.modal({
        //         title: 'Create Form',
        //         okay: {
        //             label: 'CREATE',
        //             visible: true,
        //             disabled: false,
        //             callback: async (component) => {

        //                 let request = {
        //                     // ID: 0,
        //                     post_title: component.data.title,
        //                     post_name: component.data.title,
        //                     post_excerpt: component.data.excerpt, 
		// 					meta_input: {
		// 						/**
		// 						 * Create metadata to link Wordkbook and Form (one-to-many)
		// 						 */
		// 						workbook: component.data.workbook,
		// 						group: component.data.group,
		// 						type: component.data?.type?.toLowerCase() || 'generic'
		// 					}
        //                 };


        //                 console.log('createForm', request);

		// 				let response = await this.$store.dispatch('createForm', request);
						
        //                 await this.updateFormsOrder(response.ID);

        //                 await this.refresh(true);

		// 				this.setCurrentForm(response);

		// 				typeof callback == 'function' && callback.apply(null, [response]);

		// 				this.$root.toast(`The form "${component.data.title}" has been created.`);
						
        //             }
        //         }, 

        //     }, 'ModalContentForm');

        //     setTimeout(() => {
		// 		/**
		// 		 * Setup opportunity data as copy to prevent Vue reactivity from
		// 		 * editing data in $store.state as user types-in in form
		// 		 */
		// 		let content = modal.content();
        //         content.data.title = '';
        //         content.data.excerpt = '';
        //         content.data.workbook = this.current.workbook.ID;
        //         content.data.group = this.current.workbook.meta?.group || '';
		// 		content.validate = function() {
		// 			let isValid = false;
		// 			if('title' in this.data && this.data.title?.length > 0) {
		// 				isValid = true;
		// 			}
		// 			if(!isValid) {
		// 				alert('Please fill out all required fields');
		// 			}
		// 			return isValid;
		// 		};
        //     }, 0);

		// 	return modal;
        // },



        /**
         * @params {Object} form
         * @params {Object} form.ID
         * @params {Object} form.title
         * @params {Object} form.name
         * @params {Object} form.excerpt
         */
        // editFormIntent(form) {

		// 	console.log('editFormIntent', form);

        //     let modal = this.$root.modal({
        //         title: 'Update Form',
        //         context: form,
        //         okay: {
        //             label: 'UPDATE',
        //             visible: true,
        //             disabled: false,
        //             callback: async (component) => {
        //                 let request = {
        //                     ID: form?.ID,
        //                     post_title: component.data.title,
        //                     post_excerpt: component.data.excerpt,
        //                     meta_input: {
        //                         workbook: component.data.workbook,
        //                         group: component.data.group,
		// 						type: component.data?.type?.toLowerCase() || 'generic'
        //                     }
        //                 };
        //                 this.editForm(request);
        //             }
        //         }, 

        //     }, 'ModalContentForm');

        //     setTimeout(() => {
		// 		let content = modal.content();
        //         content.data.title = form.title;
        //         content.data.excerpt = form.excerpt;
        //         content.data.workbook = form?.meta?.workbook;
        //         content.data.group = form?.meta?.group || '';
        //         content.data.type = form?.meta?.type || 'generic';
		// 		content.validate = function() {
		// 			let isValid = false;
		// 			if('title' in this.data && this.data.title?.length > 0) {
		// 				isValid = true;
		// 			}
		// 			if(!isValid) {
		// 				alert('Please fill out all required fields');
		// 			}
		// 			return isValid;
		// 		};
        //     }, 0);
        // },

        /**
         * @params {Object} request
         * @params {Object} request.ID
         * @params {Object} request.post_title
         * @params {Object} request.post_excerpt
         */
        // async editForm(request) {

		// 	console.log(request);
        //     await this.$root.$store.dispatch('updateForm', request);
        //     await this.refresh(true);
        //     this.$root.toast(`The workbook "${request.post_title}" has been updated.`);
        // },


		/**
		 * 
		 * @param {Object} data The for data
		 * @param {Object} data.ID 
		 * @param {Object} data.title
		 * @param {Object} data.name
		 * @param {Object} data.excerpt
		 * 
		 */
		// deleteForm(data) {
		// 	this.$root.notify({
		// 		title: 'Confirm',
		// 		type: 'confirm',
		// 		message: 'Are you sure you want to delete this form (' + data.title + ')?',
		// 		callback: async (okay)=> {
		// 			if(okay) {
		// 				await this.$store.dispatch('deleteForm', data);
		// 				this.$root.toast(`The form "${data.title}" has been deleted.`);
		// 				this.forms = await this.$store.dispatch('getFormsByWorkbookId', { workbookId: this.current.workbook.ID, forceRefresh: true } );
		// 			}
		// 		}
		// 	});
		// },

		/**
		 * 
		 * 
		 * Life cycle
		 * - open file chooser
		 * - convertCSV string to Array of Objects
		 * - Ask to confirm importing records in CSV
		 * - open create form modal
		 * - add file name as title
		 * - if add records w
		 */
		importFormIntent() {
			this.$root.grabFile({
				title: 'Import from .csv',
				message: 'Choose the .csv file that you wish to import.',
				okay: {
					label: 'UPLOAD',
					callback: (uploader)=> {
						console.log(uploader)

						let data, 
							fields, 
							name = uploader?.file?.name,
							csvText = uploader?.content;

						if(csvText) {
							/**
							 * TODO: handle errors
							 * 
							 * 
							 */
							data = this.$csv.toObjects(csvText, {delimiter: ','});
							fields = Object.keys(data[0]);
							console.log(data);


							// allow modal to hide (500ms)
							setTimeout(()=>{
								// import records after creating form and fields
								let modal = this.createForm( async (formResponse /** form data */) => {
									await this.addFieldsToForm(formResponse, fields);
									await this.addEntriesToForm(formResponse, data);
								});

								// wait for form content  to be mounted
								setTimeout(()=>{
									let content = modal.content();
									content.data.title = name.replace(/\.[^.]+$/, '');
								}, 0);
							}, 500)

							

							// setTimeout(()=>{
							// 	this.$root.notify({
							// 		type: 'confirm',
							// 		message: `Would you like to import all ${data?.length} records?`,
							// 		callback: (okay)=>{
							// 			setTimeout(()=>{
							// 				let modal;
							// 				if(okay) {
							// 					// import records after creating form and fields
							// 					modal = this.createForm( async (formResponse /** form data */) => {
							// 						let fieldsResponse = await this.addFieldsToForm(formResponse, fields);
							// 						let entriesReposnse = await this.addEntriesToForm(formResponse, data);

							// 						fieldsResponse, entriesReposnse;

							// 						console.log('fieldsResponse', fieldsResponse);
							// 						console.log('entriesReposnse', entriesReposnse);

							// 					});
							// 				} else {
							// 					modal = this.createForm( async (formResponse /** form data */) => {
							// 						let fieldsResponse = await this.addFieldsToForm(formResponse, fields);
							// 						fieldsResponse;

							// 						console.log('fieldsResponse', fieldsResponse);

							// 					});
							// 				}

							// 				// wait for form content  to be mounted
							// 				setTimeout(()=>{
							// 					let content = modal.content();
							// 					console.log('modal', modal);
							// 					console.log('content', content);
							// 					content.data.title = name.replace(/\.[^.]+$/, '');
							// 				}, 0);
							// 			}, 500)
							// 		}
							// 	})
							// }, 500)
						}
					}
				}
			})
		},


		/**
		 * This method will remove any existing fields in the form.
		 * 
		 * @params {Array} form
		 * @params {Array} fields Array with all the field names (e.g. ['Fisrt Name', 'Email', ...])
		 * 	IMPORTANT: names will be sanitized
		 * 
		 */
		async addFieldsToForm(form, fields) {
			console.log('addFieldsToForm', form, fields);

			return new Promise( (resolve) => {
				this.$root.notify({
					type: 'prompt',
					title: `Set Primary Field`,
					message: 'Which of these fields should be used as the primary field? ' + fields.join(', '),
					value: fields[0],
					callback: async (okay, value) =>{
						if(okay) {
							if(!fields.includes(value.trim())) {
								alert('ERROR: invlid primary field.')
								return;
							}

							let primary = this.sanitizeFieldName(value.trim());
							let content = form.content || { fields: [] };
								content.fields = [];
								content.primaryField = primary;
							
							fields.forEach( (item) => {
								let key = this.sanitizeFieldName(item);
								let field = {
									title: item,
									name: key, 
									key: key, 
									description: '', 
									hint: '',
									private: false,
									type: 'text',
									group: '',
								}

								content.fields.push(field);
							});


							let request = {
								ID: form.ID,
								post_content: content
							};

							await this.$store.dispatch('updateForm', request);
							await this.refresh(true);
							this.$root.toast(`${fields.length} fields were added to "${form.title}".`);

							resolve(true);
						}
					}
				});
			});
		},



		// async addEntries(form, entries) {

		// },



		async addEntriesToForm(form, entries) {
			console.log('addEntriesToForm', form, entries);

			return new Promise( (resolve)=> {
				let fields = Object.keys(entries[0]);

				this.$root.notify({
					type: 'confirm',
					title: `Add New Entries`,
					message: `We found ${entries.length} entries. Would you like to import them?`,
					callback: async (okay) =>{
						if(okay) {
							let primary = form?.content?.primaryField? form?.content?.primaryField : this.sanitizeFieldName(fields[0]);
							let request = [];

							// build request objet
							entries.forEach( (entry) => {
								let entrySanitized = {};
								
								// sanitize entry keys 
								fields.map( (field) => {
									let key = this.sanitizeFieldName(field);
									entrySanitized[key] = entry[field];
								});

								let title = entrySanitized[ primary ];


								// add title if missing
								// if(!entrySanitized?.title) {
								// 	entrySanitized.title = title;
								// }

								// create request object
								request.push({
									post_title: title, 
									post_content: entrySanitized, 
									meta_input: {
										form: form.ID
									}
								});
							});

							console.log(request);

							let size = 20;
							let i, j, chunk;
							for (i = 0,j = request.length; i < j; i += size) {
								chunk = request.slice(i, i + size);
								console.log('chunk', chunk);
								await this.$store.dispatch('createEntries', chunk);
							}


							this.$root.toast(`${entries.length} new entries were created.`);

							resolve(true);

						} else {
							resolve(true);
						}
					}
				});


			});

			


		},





		selectField(data) {
			this.current.field = data;
		},

		/**
		 * Field methods
		 */

        /**
         * Add a new field to the currently opened form
         * 
         */
		addFormField() {
            let modal = this.$root.modal({
                title: 'Add Field',
                okay: {
                    label: 'CREATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
						component

						let field = {...component.data};

                        let request = {
                            form_id: this.current.form?.ID,
                            field: field
                        };

                        console.log('addFieldToForm', request);
						console.log('component.data', field.data);

						await this.$root.$store.dispatch('addFieldToForm', request);

						// refresh forms only
						this.refresh(true);

						this.$root.toast(`The field "${component.data.title}" has been added to the form "${this.current.form?.title}".`);

						this.selectField(field);
						
                    }
                }, 

            }, 'ModalContentFormField');

            setTimeout(() => {
				/**
				 * Setup opportunity data as copy to prevent Vue reactivity from
				 * editing data in $store.state as user types-in in form
				 */
				let content = modal.content();
                content.data.title = '';
                content.data.name = 'field_' + Number(new Date());
                content.data.description = '';
                content.data.hint = '';
				content.validate = function() {
					let isValid = false;
					if('title' in this.data && this.data.title?.length > 0) {
						isValid = true;
					}
					if(!isValid) {
						alert('Please fill out all required fields');
					}
					return isValid;
				};
            }, 0);

			return modal;
		},



		fieldIcon(type) {
			let icon = 'text_fields';
			switch (type) {
				case 'textarea':
					icon = 'notes';
					break;

				case 'color':
					icon = 'palette';
					break;

				case 'number':
					icon = 'tag';
					break;

				case 'url':
					icon = 'link';
					break;

				case 'email':
					icon = 'email';
					break;

				case 'tel':
					icon = 'call';
					break;
			
				case 'text':
				default:
					break;
			}
			return icon;
		},


		async updateFieldData(data) {
			let request = {
				form_id: this.current.form?.ID,
				field: data
			};

			console.log('updateFieldData', request);

			await this.$root.$store.dispatch('updateFormField', request);

			this.$root.toast(`The field "${data.title}" has been updated.`);
		},





        /**
         * Select a workbook
         */
        selectAppFromWorkbookId(id, callback) {
            let modal = this.$root.modal({
                title: 'Select App',
                okay: {
                    label: 'SELECT',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        typeof callback == 'function' && callback.apply(null, [component.current.selection]);
                    }
                }, 

            }, 'ModalContentSelector');

            setTimeout(async () => {
                let items = await this.$store.dispatch('getAppsByWorkbookId', id);
				let content = modal.content();
                    content.current.selection = null;
                    content.data.items = items;
            }, 0);

			return modal;
        },


		resetAfter(panel) {
			switch (panel) {
				case 'form':
					this.$refs.fieldDetails.attachSource({});
					this.current.field = null;
					break;
			
				case 'field':
				default:
					break;
			}
		},



		sanitizeFieldName(name) {
			return name.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '_');
		},

        saveGeneral: async ()=>{}, 
        saveFieldDetails: async ()=>{}, 



        initFields() {
            // general
            this.$el.querySelector('#meta-type').addEventListener('keyup', () => {
                this.saveGeneral();
            });

            this.$el.querySelector('#meta-group').addEventListener('keyup', () => {
                this.saveGeneral();
            });

            this.$el.querySelector('#form-title').addEventListener('keyup', () => {
                this.saveGeneral();
            });
            this.$el.querySelector('#form-excerpt').addEventListener('keyup', () => {
                this.saveGeneral();
            });

            // details
            setTimeout(()=>{
                this.$el.querySelectorAll('.form-details .form-control').forEach((item)=>{
                    item.addEventListener('keyup', () => {
                        this.saveFieldDetails();
                    });
                })
                this.$el.querySelectorAll('.form-details .form-select').forEach((item)=>{
                    item.addEventListener('change', () => {
                        this.saveFieldDetails();
                    });
                })
            }, 0);
            
            

        },


        deeplink() {
            let field, fields = this.current?.form?.content?.fields;
            if(!fields) {
                return;
            }

            if(this.$route.params?.field) {
                console.log(this.$route.params?.field);
                fields.forEach((item)=>{
                    if(item?.name == this.$route.params.field) {
                        field = item;
                    }
                });
            } else if(fields?.length) {
                field = fields[0]
            }

            if(field) {
                this.setCurrentField(field);
            }
        },

		/**
		 * Others
		 */
		async refresh(forceRefresh = false) {
            forceRefresh
			let name = this.$route.params?.form || null;
            this.current.form = null;
			if(name) {
				await this.$store.dispatch('getForm', name);
				this.current.form = this.$store.state.current.form;
			}
		}
	},


    beforeMount() {
        this.saveGeneral = debounce(async function() {
            let type = this.$el.querySelector('#meta-type').value;
            let group = this.$el.querySelector('#meta-group').value;
            let title = this.$el.querySelector('#form-title').value;
            let excerpt = this.$el.querySelector('#form-excerpt').value;

            let meta = {};
                type?.length && (meta.type = type);
                group?.length && (meta.group = group);

            let request = {
                ID: this.current.form.ID,
                meta_input: meta
            };

            title?.length && (request.post_title = title)
            excerpt?.length && (request.post_excerpt = excerpt);

            await this.$store.dispatch('updateForm', request);
			this.$root.toast(`The form data has been updated.`);
        }, 1000);


        this.saveFieldDetails = debounce(async function() {
            let title = this.$el.querySelector('#field-title').value;
            let key = this.$el.querySelector('#field-key').value;
            let hint = this.$el.querySelector('#field-hint').value;
            let type = this.$el.querySelector('#field-type').value;

            let description = this.$el.querySelector('#field-description')? this.$el.querySelector('#field-description').value : null;
            let def = this.$el.querySelector('#field-default')? this.$el.querySelector('#field-default').value : null;
            let options = this.$el.querySelector('#field-options')? this.$el.querySelector('#field-options').value : null;

            this.current.field['title'] = title;    
            this.current.field['key'] = key;    
            this.current.field['hint'] = hint;    
            this.current.field['type'] = type;    

            description && (this.current.field['description'] = description);    
            def && (this.current.field['default'] = def);    
            options && (this.current.field['options'] = options);   

            console.log(this.current.field);

            let request = {
                ID: this.current.form.ID,
                post_content: this.current.form.content
            };

            await this.$store.dispatch('updateForm', request);
			this.$root.toast(`The field ${this.current.field.title} has been updated.`);
        }, 1000);
    }, 

	async mounted() {
		await this.refresh(true);
        this.initFields();
        this.deeplink();

		document.location.hostname == 'localhost' && (window.form = this);

	}
};
</script>

<style scope>
.min-height {
	min-height: 50vh;
}


.panel-a {
	width: 360px;
	z-index: 30;
}
.panel-b {
	width: auto;
	z-index: 20;
}
.panel-c {
	width: 480px;
	z-index: 10;
}

/* .content-editor-extras {
	width: 360px;
	z-index: 20;
}

.content-editor-sidebar {
	width: 360px;
	z-index: 10;
}

.content-editor-details {
	width: 500px;
} */

.list-group-item.active {
	background-color: var(--primary-light);
	border-color: transparent;
	color: rgb(33, 37, 41);
}
.list-group-item .fw-500 {
	font-weight: 500;
}


.list-group-item .list-group-item-options {
	opacity: 0;
	transition: all 0.2s;
}
.list-group-item:hover .list-group-item-options {
	opacity: 1;
}


.align-to-left .dropdown-menu {
    margin-left: 164px !important;
}

</style>