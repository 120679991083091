


export default [
    { name: 'Text',  type: 'text', icon: 'text_fields' },
    { name: 'Paragraph',  type: 'textarea', icon: 'notes' },
    { name: 'Name',  type: 'name', icon: 'badge' },
    { name: 'Number',  type: 'number', icon: 'tag' },
    { name: 'Email',  type: 'email', icon: 'email' },
    { name: 'URL',  type: 'url', icon: 'link' },
    { name: 'Phone',  type: 'tel', icon: 'call' },
    { name: 'Color',  type: 'color', icon: 'palette' },
    { name: 'Hidden',  type: 'hidden', icon: 'visibility_off' },
    { name: 'File',  type: 'file', icon: 'drive_folder_upload' },
]